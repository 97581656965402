// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-international-js": () => import("/opt/build/repo/src/pages/international.js" /* webpackChunkName: "component---src-pages-international-js" */),
  "component---src-pages-links-js": () => import("/opt/build/repo/src/pages/links.js" /* webpackChunkName: "component---src-pages-links-js" */),
  "component---src-pages-success-js": () => import("/opt/build/repo/src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */)
}

